var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "modal-class": _vm.modalClass,
        "content-class": _vm.contentClass,
        "body-class": _vm.bodyClass,
        "dialog-class": _vm.dialogClass,
        id: _vm.id,
        size: _vm.size,
        okOnly: _vm.okOnly,
        "no-fade": _vm.noFade,
        okTitle: _vm.okTitle,
        centered: _vm.centered,
        hideFooter: _vm.hideFooter,
        hideHeader: _vm.hideHeader,
        scrollable: _vm.scrollable,
        noStacking: _vm.noStacking,
        noCloseOnEsc: _vm.noCloseOnEsc,
        noCloseOnBackdrop: _vm.noCloseOnBackdrop,
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ close }) {
            return [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h3", { staticClass: "mb-0 text-primary" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "link" },
                  on: {
                    click: function ($event) {
                      return close()
                    },
                  },
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "x-circle-fill", variant: "info" },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }