<template>
  <!-- v-b-modal.id to call modal-->
  <b-modal
    :modal-class="modalClass"
    :content-class="contentClass"
    :body-class="bodyClass"
    :dialog-class="dialogClass"
    :id="id"
    :size="size"
    :okOnly="okOnly"
    :no-fade="noFade"
    :okTitle="okTitle"
    :centered="centered"
    :hideFooter="hideFooter"
    :hideHeader="hideHeader"
    :scrollable="scrollable"
    :noStacking="noStacking"
    :noCloseOnEsc="noCloseOnEsc"
    :noCloseOnBackdrop="noCloseOnBackdrop"
    @close="close"
  >
    <template v-slot:modal-header="{ close }">
      <!-- name of patient -->
      <b-container fluid>
        <b-row>
          <b-col>
            <h3 class="mb-0 text-primary">{{ title }}</h3>
          </b-col>
        </b-row>
      </b-container>
      <!-- Emulate built in modal header close button action -->
      <b-button size="sm" variant="link" @click="close()">
        <b-icon icon="x-circle-fill" variant="info"></b-icon>
      </b-button>
    </template>
    <!-- custom content -->
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    modalClass: {
      type: String,
    },
    contentClass: {
      type: String,
    },
    bodyClass: {
      type: String,
    },
    dialogClass: {
      type: String,
    },
    size: {
      type: String,
    },
    title: {
      type: String,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    okTitle: {
      type: String,
      default: "OK",
    },
    okOnly: {
      type: Boolean,
      default: false,
    },
    noStacking: {
      type: Boolean,
      default: false,
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    noFade: {
      type: Boolean,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style scoped>
/deep/.modal-header {
  border-bottom: 0;
  align-items: center;
}

/deep/.modal-footer {
  border-top: 0;
  align-items: center;
}

/deep/.terms-modal .modal-header {
  padding-left: 10px;
  padding-right: 10px;
  font-family: "hattonmedium";
  letter-spacing: initial;
}

/deep/.agreement .modal-content {
  background-color: #f8f4ff;
}

/deep/.agreement .modal-content h2 {
  padding: 1em;
  letter-spacing: 0;
}
</style>
